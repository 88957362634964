import React from "react"
import { Link } from "gatsby"

import "./index.css"

import SEO from "../components/seo"
import { Menu } from "../components/menu"
import { Message } from "../components/message"
import { UpdateBackground } from "../components/update/background/background"

const Content = () => {
  return (
    <Message
      height={675}
      width={275}
    >
      <div style={{ marginBottom: "24px" }}>Dear Friends and Family,</div>
      <div style={{ marginBottom: "24px" }}>Due to the on-going pandemic, gatherings in Alberta have been restricted.
      </div>
      <div style={{ marginBottom: "24px" }}>After prayerful discernment, we have chosen to make our vows on June 27 @ 1PM as
        planned, with only our families in attendance.
      </div>
      <div style={{ marginBottom: "24px" }}>As much as it pains us that we cannot celebrate with you physically, we
        would love for you to join us virtually.
      </div>
      <div style={{ marginBottom: "24px" }}>A link will be made available to the live stream, on <Link
        className={"underline"} to={"/"}>The Big Day</Link>.
      </div>
      <div style={{ marginBottom: "24px" }}>We are so grateful to have you in our lives, and for your prayers, as we
        prepare to begin this journey together.
      </div>
      <div style={{ marginBottom: "24px" }}>We cannot wait to rejoice with you at our next meeting.</div>
      <div>Love,</div>
      <div>Bradley & Edrienne (soon to be) Abel</div>
    </Message>
  )
}

const Page = (props) => {
  return (
    <>
      <Menu location={props.location}/>
      <Content/>
      <UpdateBackground/>
    </>
  )
}

const Update = (props) => (
  <>
    <SEO title="Update"/>
    <Page {...props}/>
  </>
)

export default Update
