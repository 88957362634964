import React from "react"

import { Background } from "../../background"

import { useDesktopImage } from "./deskktop-image"
import { useMobileImage } from "./mobile-image"

export const UpdateBackground = () => {
  const desktop = useDesktopImage()
  const mobile = useMobileImage()

  return (
    <Background
      desktop={desktop}
      mobile={mobile}
    />
  )
}