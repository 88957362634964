import { useStaticQuery, graphql } from "gatsby"

export const useMobileImage = () => {
  const {file} = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "update-mobile.jpg" }) {
        childImageSharp {
          fixed(width: 1861, height: 1282) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return file.childImageSharp.fixed.src;
};
